import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Styled from 'jobDescriptionFullApplyForm_styled';
import { Dropdown } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import {
  savePersonalInformationQuestions,
  savePersonalQuestionsSubmitRequirements,
} from '../../../../../../../actions/jobApplication_actions';
import {
  countries,
  states,
} from '../../../../../../../../constants/dataConstants';

class PersonalInformationQuestion extends Component {
  state = {
    questionType: 'personQuestion',
    inputSuccessCheck: {
      // all the properties here can be only true or false. We put null at beginning in order to not apply statedesign when app first renders
      firstNameInput: false,
      lastNameInput: false,
      addressInput: false,
      cityInput: false,
      stateInput: false,
      zipCodeInput: false,
      countryInput: false,
      emailInput: false,
      phoneInput: false,
    },
  };

  componentDidMount() {
    this.checkOptionalConditions();
  }

  componentDidUpdate(prevProps) {
    const {
      first_name,
      last_name,
      address,
      city,
      state,
      zip_code,
      country,
      email,
      mobile_phone_number,
    } = this.props.personQuestion;

    const checkChangedInputs = () => {
      /* Compare previous state and current for all monitored inputs and if its changed we
      call the function */

      const firstNameInput =
        JSON.stringify(prevProps.personQuestion.first_name) !==
        JSON.stringify(first_name);
      const lastNameInput =
        JSON.stringify(prevProps.personQuestion.last_name) !==
        JSON.stringify(last_name);
      const addressInput =
        JSON.stringify(prevProps.personQuestion.address) !==
        JSON.stringify(address);
      const cityInput =
        JSON.stringify(prevProps.personQuestion.city) !== JSON.stringify(city);
      const stateInput =
        JSON.stringify(prevProps.personQuestion.state) !==
        JSON.stringify(state);
      const zipCodeInput =
        JSON.stringify(prevProps.personQuestion.zip_code) !==
        JSON.stringify(zip_code);
      const countryInput =
        JSON.stringify(prevProps.personQuestion.country) !==
        JSON.stringify(country);
      const emailInput =
        JSON.stringify(prevProps.personQuestion.email) !==
        JSON.stringify(email);
      const phoneInput =
        JSON.stringify(prevProps.personQuestion.mobile_phone_number) !==
        JSON.stringify(mobile_phone_number);

      /* In parenthesis we put all the inputs that could have changed and if any of them has
      we call the function */
      if (
        firstNameInput ||
        lastNameInput ||
        addressInput ||
        cityInput ||
        stateInput ||
        zipCodeInput ||
        countryInput ||
        emailInput ||
        phoneInput
      ) {
        this.checkOptionalConditions();
      }
    };

    checkChangedInputs();
  }

  checkOptionalConditions = () => {
    const {
      first_name,
      last_name,
      address,
      city,
      state,
      zip_code,
      country,
      email,
      mobile_phone_number,
    } = this.props.personQuestion;

    /* Make conditions when specific inputs are considered true and successfully filled out */
    /* --------------------- */
    let firstNameInput = false;
    let lastNameInput = false;
    let addressInput = false;
    let cityInput = false;
    let stateInput = false;
    let zipCodeInput = false;
    let countryInput = false;
    let emailInput = false;
    let phoneInput = false;

    /* check every property individually and set it to true based on custom condition */
    if (first_name && first_name.trim().length > 0) {
      firstNameInput = true;
    }
    if (last_name && last_name.trim().length > 0) {
      lastNameInput = true;
    }
    if (address && address.trim().length > 0) {
      addressInput = true;
    }
    if (city && city.trim().length > 0) {
      cityInput = true;
    }
    if (state && state.trim().length > 0) {
      stateInput = true;
    }
    if (zip_code && zip_code.trim().length > 0) {
      zipCodeInput = true;
    }
    if (country && country.trim().length > 0) {
      countryInput = true;
    }
    if (email && email.trim().length > 0) {
      emailInput = true;
    }
    if (this.formatPhoneNumberWithUSCode(mobile_phone_number)) {
      phoneInput = true;
    }
    /* --------------------- */

    /* Set new state after checking conditions for the inputs */
    this.setState(
      {
        inputSuccessCheck: {
          ...this.state.inputSuccessCheck,
          firstNameInput,
          lastNameInput,
          addressInput,
          cityInput,
          stateInput,
          zipCodeInput,
          countryInput,
          emailInput,
          phoneInput,
        },
      },
      () => {
        /* We turn the object into array of boolean values */
        const inputSuccessList = Object.values(this.state.inputSuccessCheck);

        let submitReady = true;
        /* If one of the values in the array is false, it means one of the inputs is not filled correctly and we set submitReady to false.
          Until submit ready is true we can not submit application. */
        for (let i = 0; i < inputSuccessList.length; i += 1) {
          if (inputSuccessList[i] === false) {
            submitReady = false;
            break;
          }
        }
        /* We save if the question is submitReady or not to redux store */
        this.props.savePersonalQuestionsSubmitRequirements(
          this.state.questionType,
          submitReady,
        );
      },
    );
  };

  handleInputChange = (questionType, property, value, index) => {
    /* Backend accepts `null` so if there is nothing in input instead of empty
    string we send `null` */
    this.props.savePersonalInformationQuestions(
      questionType,
      property,
      value,
      index,
    );
  };

  formatPhoneNumberWithUSCode(phoneNumber) {
    const formatUSPhoneNumber = (cleaned) => {
      const areaCode = cleaned.slice(0, 3);
      const middle = cleaned.slice(3, 6);
      const last = cleaned.slice(6, 10);

      return `+1-${areaCode}-${middle}-${last}`;
    };

    const cleaned = `${phoneNumber}`.replace(/\D/g, '');

    if (cleaned.length === 10) {
      return formatUSPhoneNumber(cleaned);
    }
    if (cleaned.length === 11 && cleaned[0] === '1') {
      return formatUSPhoneNumber(cleaned.slice(1));
    }

    return '';
  }

  checkQuestionDesign(property) {
    const { firstTimeSubmitButtonClicked } = this.props;
    const { inputSuccessCheck } = this.state;

    /* Depending if input or some other element on the page is filled out correctly, wrongly or nothing we change design */
    let warning;
    let success;
    let neutral;

    const returnDesignPattern = () => {
      if (warning) {
        return warning;
      }

      if (success) {
        return success;
      }

      return neutral;
    };

    /* Checks for which design we are gonna use on which element */
    /* ---------------------------------------------- */

    const checkInputWarning = (name) => {
      /* Warning input design can only show up after we clicked the submit button if
      input is not filled out correctly */
      if (firstTimeSubmitButtonClicked && inputSuccessCheck[name] === false) {
        return 'warning';
      }
    };

    const checkInputSuccess = (name) => {
      /* We show success input design before we hit submit button for the first
      time and after also so the only condition is that input is filled out correctly. */
      if (inputSuccessCheck[name] === true) {
        return 'success';
      }
    };

    /* We show warning design only after submit button is clicked for the first TimeRanges,
    if one of the inputs is not filled out correctly */
    const checkQuestionContainerWarning = () => {
      if (
        firstTimeSubmitButtonClicked &&
        Object.keys(inputSuccessCheck).some((k) => !inputSuccessCheck[k]) // check if any of the property in inputSuccessCheck is false
      ) {
        return 'warning';
      }
    };

    /* Check the whole question container design. If all the inputs are filled out correctly we
    show success design */

    const checkQuestionContainerSuccess = () => {
      /* check if all the properties in inputSuccessCheck are true */
      if (
        Object.keys(inputSuccessCheck).every(
          (k) => inputSuccessCheck[k] === true,
        )
      ) {
        return 'success';
      }
    };
    /* ---------------------------------------------- */

    /* based on the input that we are checking we are passing that property to check design */
    switch (property) {
      case 'firstNameInput':
      case 'lastNameInput':
      case 'addressInput':
      case 'cityInput':
      case 'stateInput':
      case 'zipCodeInput':
      case 'countryInput':
      case 'emailInput':
      case 'phoneInput':
        warning = checkInputWarning(property);
        success = checkInputSuccess(property);
        neutral = 'neutral';

        return returnDesignPattern();

      case 'questionContainer':
        warning = checkQuestionContainerWarning();
        success = checkQuestionContainerSuccess();
        neutral = 'neutral';

        return returnDesignPattern();
      default:
        return returnDesignPattern();
    }
  }

  render() {
    const {
      first_name,
      last_name,
      address,
      city,
      state,
      zip_code,
      country,
      email,
      mobile_phone_number,
    } = this.props.personQuestion;
    const { t } = this.props;
    return (
      <Styled.PersonalQuestionContainer
        statedesign={this.checkQuestionDesign('questionContainer')}
      >
        <Styled.FormGroup>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('firstNameInput')}
            >
              <input
                placeholder={t('firstName')}
                onChange={(e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'first_name',
                    e.target.value,
                    null,
                  )
                }
                value={first_name || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>

          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('lastNameInput')}
            >
              <input
                placeholder={t('lastName')}
                onChange={(e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'last_name',
                    e.target.value,
                    null,
                  )
                }
                value={last_name || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('addressInput')}
            >
              <input
                placeholder={t('address')}
                onChange={(e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'address',
                    e.target.value,
                    null,
                  )
                }
                value={address || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('cityInput')}
            >
              <input
                placeholder={t('city')}
                onChange={(e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'city',
                    e.target.value,
                    null,
                  )
                }
                value={city || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('stateInput')}
            >
              <Dropdown
                placeholder={t('state')}
                fluid
                search
                selection
                options={states}
                onChange={(e, value) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'state',
                    value.value,
                    null,
                  )
                }
                value={state || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('zipCodeInput')}
            >
              <input
                placeholder={t('postalCode')}
                onChange={(e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'zip_code',
                    e.target.value,
                    null,
                  )
                }
                value={zip_code || ''}
                type="number"
                min={0}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('countryInput')}
            >
              <Dropdown
                placeholder={t('country')}
                fluid
                search
                selection
                options={countries}
                onChange={(e, value) => {
                  this.handleInputChange(
                    this.state.questionType,
                    'country',
                    value.value,
                    null,
                  );
                }}
                value={country || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('emailInput')}
            >
              <input
                placeholder={t('email')}
                onChange={(e) =>
                  this.handleInputChange(
                    this.state.questionType,
                    'email',
                    e.target.value,
                    null,
                  )
                }
                disabled
                type="email"
                value={email || ''}
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
          <Styled.FormField>
            <Styled.InputDesignWrapper
              statedesign={this.checkQuestionDesign('phoneInput')}
            >
              <input
                placeholder="e.g. +1-555-555-5555"
                pattern="^\+?[0-9\s\-\(\)]{10,15}$"
                minLength={10}
                maxLength={16}
                required
                onChange={(e) => {
                  const { value } = e.target;

                  if (value.length >= 10) {
                    this.handleInputChange(
                      this.state.questionType,
                      'mobile_phone_number',
                      this.formatPhoneNumberWithUSCode(value),
                      null,
                    );
                  }
                }}
                type="text"
              />
            </Styled.InputDesignWrapper>
          </Styled.FormField>
        </Styled.FormGroup>
      </Styled.PersonalQuestionContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  firstTimeSubmitButtonClicked:
    state.jobApplicationReducer.submitRequirements.firstTimeSubmitButtonClicked,
  personQuestion:
    state.jobApplicationReducer.fullApplicationPersonalQuestions.personQuestion,
});

PersonalInformationQuestion.propTypes = {
  firstTimeSubmitButtonClicked: PropTypes.bool, // if true it means that "Submit" form button was clicked at least once.
  personQuestion: PropTypes.object, // saved inputs data that user filled out
  savePersonalInformationQuestions: PropTypes.func, // used to save personal information questions input data to redux store
  savePersonalQuestionsSubmitRequirements: PropTypes.func, // used to save if personal questions are submit ready
};

export default connect(mapStateToProps, {
  savePersonalInformationQuestions,
  savePersonalQuestionsSubmitRequirements,
})(withTranslation('jobDescriptionFullApply')(PersonalInformationQuestion));
